<template>
  <div class="lang-selector">
    <b-dropdown
      :scrollable="isScrollable"
      :max-height="maxHeight"
      v-model="currentMenu"
      aria-role="list"
    >
      <template #trigger>
        <b-image
          style="width: 24px; border-radius: 20%"
          :src="getImgUrl(currentMenu.icon)"
        ></b-image>
      </template>

      <b-dropdown-item
        v-for="(menu, index) in menus"
        :key="index"
        :value="menu"
        aria-role="listitem"
      >
        <div class="media">
          <b-image
            style="width: 24px; border-radius: 20%"
            :src="getImgUrl(menu.icon)"
          ></b-image>
          <div class="media-content">
            <h3 style="padding-left: 10px">{{ menu.text }}</h3>
          </div>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
export default {
  name: "LanguageSelector",
  data() {
    return {
      isScrollable: false,
      maxHeight: 200,
      currentMenu:
        this.$i18n.locale === "es-ES"
          ? { icon: "es.png", text: "Española", lang: "es" }
          : this.$i18n.locale === "fr-FR"
          ? { icon: "fr.png", text: "Français", lang: "fr" }
          : { icon: "en.png", text: "English", lang: "en" },
      menus: [
        { icon: "fr.png", text: "Français", lang: "fr" },
        { icon: "en.png", text: "English", lang: "en" },
        { icon: "es.png", text: "Española", lang: "es" },
      ],
    };
  },
  watch: {
    currentMenu(newItem) {
      this.$i18n.locale = newItem.lang;
      localStorage.setItem("lang", newItem.lang);
    },
  },
  methods: {
    getImgUrl(pic) {
      return require("../../assets/" + pic);
    },
  },
};
</script>
<style scoped>
.media {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.lang-selector {
}
</style>
