<template>
  <div class="home">
    <AdditionalSales :payment-id="this.paymentId" />
  </div>
</template>

<script>
// @ is an alias to /src
import AdditionalSales from "@/components/AdditionalSales.vue";
export default {
  name: "AdditionalSalesView",
  data() {
    return {
      paymentId: this.$route.params.paymentId,
    };
  },
  components: {
    AdditionalSales,
  },
};
</script>
<style scoped>
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
</style>
