import { backendURL } from "@/config";
import axios from "axios";

const apiClient = axios.create({
  baseURL: backendURL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  getGoogleMapsPredictions(input) {
    return axios
      .create({
        baseURL: `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${input}&key=AIzaSyBhjDPLH5mGWpKNWFNPy8UjtvFN6J4oop0`,
      })
      .get()
      .predictions.map((prediction) => prediction.description);
  },
  createIdVerificationSession(
    paymentRequestId,
    guestFirstName,
    guestLastName,
    guestPhoneNumber,
    guestEmail
  ) {
    return apiClient.post(
      `/payment-requests/${paymentRequestId}/id-verification-sessions`,
      {
        guestFirstName: guestFirstName,
        guestLastName: guestLastName,
        guestEmail: guestEmail,
        guestPhoneNumber: guestPhoneNumber,
      }
    );
  },
  registerAdditionalSalesPayment(paymentId) {
    return apiClient.post(
      `/web/register-additional-sales-payment/${paymentId}`
    );
  },
  updateIdVerificationSession(
    paymentRequestId,
    guestFirstName,
    guestLastName,
    guestPhoneNumber,
    guestEmail
  ) {
    return apiClient.post(
      `/payment-requests/${paymentRequestId}/id-verification-sessions`,
      {
        guestFirstName: guestFirstName,
        guestLastName: guestLastName,
        guestEmail: guestEmail,
        guestPhoneNumber: guestPhoneNumber,
      }
    );
  },
  getPaymentRequest(paymentRequestId) {
    return apiClient.get("/web/payment-requests/" + paymentRequestId);
  },
  getPayment(paymentId) {
    return apiClient.get("/web/payments/" + paymentId);
  },
  createPaymentIntent(
    firstName,
    lastName,
    email,
    phoneNumber,
    paymentRequestId,
    withoutFees,
    amount = undefined,
    stripeCustomerId = undefined
  ) {
    return apiClient.post("/payment-intents", {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
      paymentRequestId: paymentRequestId,
      withoutFees: withoutFees,
      ...(amount ? { amount: amount } : {}),
      ...(stripeCustomerId ? { stripeCustomerId: stripeCustomerId } : {}),
    });
  },
  async createPayment(
    paymentRequestId,
    firstName,
    lastName,
    email,
    phoneNumber,
    totalPayedAmount,
    startDate,
    endDate,
    stripeCustomerId,
    lang,
    idVerificationSessionId
  ) {
    console.log(stripeCustomerId);
    return await apiClient.post("/web/payments", {
      paymentRequestId: paymentRequestId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
      totalPayedAmount: +totalPayedAmount,
      startDate: startDate,
      endDate: endDate,
      stripeCustomerId: stripeCustomerId,
      lang: lang,
      idVerificationSessionId: idVerificationSessionId,
    });
  },
};
