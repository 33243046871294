<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="racine">
    <div class="payment-container">
      <div class="header">
        <h4 class="title is-5">🔗 Lien de paiement invalide!</h4>
        <p>
          Malheureusement, le lien que vous avez suivi n'est plus fonctionnel.
          Il est probable que le propriétaire l'ait retiré ou qu'il ait expiré.
          Nous vous suggérons de contacter votre propriétaire pour obtenir un
          nouveau lien valide.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ErrorView",
};
</script>
<style scoped>
.racine {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.payment-container {
  max-width: 600px;
  box-shadow: 0px 12.1628px 24.3257px rgba(0, 0, 0, 0.08);
  padding-top: 20px;
  padding-bottom: 32px;
  justify-content: center;
}

h4 {
  margin-left: 25px;
  margin-right: 25px;
  color: #3c4257;
}
p {
  margin-left: 25px;
  margin-right: 25px;
}
</style>
