<!-- eslint-disable vue/no-parsing-error -->
<!-- eslint-disable prettier/prettier -->
<template>
  <div class="payment-container">
    <div class="header">
      <h4 class="title is-5"> {{ $t('hello.title', { applicantName: applicantName }) }}</h4>
      <language-selector></language-selector>
    </div>
    <div class="stripe-div" v-if="pi">
      <stripe-element-payment v-on:error="onStripeError" v-on:loading="loading = !loading"
        v-on:element-change="onStripeElementChange" ref="paymentRef" :pk="pk" :elements-options="elementsOptions"
        :confirm-params="confirmParams" class="payment" />
      <b-button @click="pay" :disabled="loading || !stripeElementComplete" type="is-primary"
        :class="{ 'is-loading': loading }">
        {{ loading ? "Patientez ..." : "Valider (" + (new Intl.NumberFormat('fr-FR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(totalAmount / 100))
        + "€)" }}
      </b-button>
      <div class="collapses">
        <b-collapse class="card" animation="slide" v-for="(collapse, index) of collapses" :key="index"
          :open="isOpen == index" @open="isOpen = index" :aria-id="'contentIdForA11y5-' + index">
          <template #trigger="props">
            <div class="card-header" role="button" :aria-controls="'contentIdForA11y5-' + index"
              :aria-expanded="props.open">
              <a class="card-header-icon">
                <b-icon :icon="props.open ? 'chevron-down' : 'chevron-right'">
                </b-icon>
              </a>
              <p class="card-header-title">
                {{ collapse.securityFees == true ? (depositWithoutFees
        ? $t("hello.securityFeesTextWithoutFeesTitle")
        : $t("hello.securityFeesTitle")) : (collapse.commitment ? $t("hello.commitmentTitle") :
          $t("hello.securityTitle")) }}
              </p>
            </div>
          </template>
          <div class="card-content">
            <div class="content">
              {{ collapse.securityFees == true ? (depositWithoutFees
        ? $t("hello.securityFeesTextWithoutFees")
        : $t("hello.securityFeesText")) : (collapse.commitment ? $t("hello.commitmentText") :
          $t("hello.securityText")) }}<span class="pointer" style="text-decoration: underline"
                @click="activateWithoutFees" v-if="collapse.securityFees == true">{{ $t('hello.doSoHere') }}</span>
              <br />
              <b-image v-if="collapse.displayImage" :src="require(`../assets/security.png`)"></b-image>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
import PaymentRequestService from "@/services";
import LanguageSelector from "./atomic/LanguageSelector.vue";
import { Crisp } from "crisp-sdk-web";
export default {
  name: "AdditionalSales",
  components: {
    StripeElementPayment,
    LanguageSelector,
  },
  props: {
    paymentId: String,
  },
  data() {
    return {
      totalAmount: 0,
      depositWithoutFees: false,
      identiyVerified: null,
      verifyIdentityChecked: true,
      stripeCustomerId: null,
      flatId: null,
      isPermalink: null,
      startDate: null,
      endDate: null,
      pi: null,
      booking: null,
      deposit: null,
      depositFees: null,
      serviceFees: null,
      applicantName: null,
      acceptedTermsOfService: false,
      firstName: null,
      lastName: null,
      birthDate: null,
      address: null,
      phoneNumber: null,
      email: null,
      loading: false,
      stripeElementComplete: false,
      payment: null,
      pk: "pk_live_51HrmdbE1srlewJHxdxuPjlaxc9HLrV5iw7wnVXcRlwT835RwAgU8BVjvXo1oxFbEWcsoRQFHMt2ffF91baPBZfqA00vi59LUbM",
      elementsOptions: {
        appearance: {}, // appearance options
      },
      confirmParams: {
        return_url: null, // success url
      },
      isOpen: 0,
      collapses: [
        {
          securityFees: false,
          commitment: true,
          bankSecurity: false,
          title: this.$t("hello.commitmentTitle"),
          text: this.$t("hello.commitmentText"),
          displayImage: false,
        },
        {
          securityFees: false,
          commitment: false,
          bankSecurity: true,
          title: this.$t("hello.securityTitle"),
          text: this.$t("hello.securityText"),
          displayImage: true,
        },
      ],
    };
  },
  watch: {
    address(old, newV) {
      console.log(newV);
    },
  },
  computed: {
    isButtonDisabled() {
      return !this.firstName ||
        !this.lastName ||
        !this.email ||
        !this.phoneNumber ||
        !this.startDate ||
        !this.endDate
        ? true
        : false;
    },
  },
  created() {
    PaymentRequestService.getPayment(this.paymentId)
      .then((response) => {
        this.payment = response.data;
        if (this.payment.additionalSalesPaymentStatus == "SUCCEEDED") {
          window.location.href = "/error";
        }
        this.firstName = this.payment.firstName;
        this.lastName = this.payment.lastName;
        this.email = this.payment.email;
        this.phoneNumber = this.payment.phoneNumber;
        this.totalAmount = this.payment.additionalSalesTotalAmount * 100;
        console.log(this.totalAmount);
        PaymentRequestService.createPaymentIntent(
          this.firstName,
          this.lastName,
          this.email,
          this.phoneNumber,
          this.payment.paymentRequest.id,
          true,
          this.payment.additionalSalesTotalAmount,
          this.payment.stripeCustomerId
        )
          .then(async (response) => {
            this.pi = undefined;
            await this.$nextTick();
            this.elementsOptions.clientSecret = response.data.pi;
            this.stripeCustomerId = response.data.stripeCustomerId;
            console.log(
              "Payment Intent Asked",
              this.elementsOptions.clientSecret
            );
            this.pi = response.data.pi;
            this.confirmParams.return_url = encodeURI(
              "https://yeswebook.co/additional-sales-success/" + this.payment.id
            );
            console.log(this.confirmParams.return_url);
            this.$el.getElementsByClassName("stripe-div").value = false;
          })
          .catch((error) => console.error(error));
        // If not permalink and already payed. Then directly to success page
        /*if (
            this.paymentRequest.payments.length != 0 &&
            !this.paymentRequest.isPermalink
          ) {
            this.$router.push({
              name: "success",
              query: {
                firstName: this.paymentRequest.payments[0].firstName,
                lastName: this.paymentRequest.payments[0].lastName,
                email: this.paymentRequest.payments[0].email,
                phoneNumber: this.paymentRequest.payments[0].phoneNumber,
                applicantName: this.paymentRequest.flatId,
                reservationId: this.paymentRequest.reservationId,
                startDate: this.paymentRequest.startDate,
                endDate: this.paymentRequest.endDate,
              },
            });
          }*/
        this.applicantName =
          this.payment["paymentRequest"]["owner"]["commercialName"];
        Crisp.session.setData({
          flat: this.flatId,
          applicantName: this.applicantName,
        });
      })
      .catch((error) => {
        window.location.href = "/error";
        console.error(error);
      });
  },
  methods: {
    onStripeError(error) {
      console.log(error.message);
      this.$buefy.notification.open({
        message: error.message,
        position: "is-bottom-right",
        type: "is-danger",
        hasIcon: true,
      });
    },
    onStripeElementChange(change) {
      this.stripeElementComplete = change.complete;
    },
    reset() {
      this.$router.push({
        name: "home",
        params: { paymentRequestId: this.paymentRequest.shortId },
      });
    },
    pay() {
      this.$refs.paymentRef.submit();
    },
  },
};
</script>
<style scoped>
.collapses {
  margin-top: 55px;
}

.data-input {
  margin-bottom: 1.5rem;
}

.data-input-firstname {
  margin: 0px 0px 0px 0px;
}

.column {
  margin: 0px 0px 0.65rem 0px;
}

hr {
  border: 0;
  border-top: 1px solid;
  margin-top: 7px;
  margin-bottom: 7px;
}

hr.rep {
  border: 0;
  border-top: 0.3px solid #dedfe0;
  margin-top: 7px;
  margin-bottom: 7px;
}

.button-container {
  text-align: center;
}

.personal-data {
  padding-left: 55px;
  padding-right: 55px;
}

.stripe-div {
  text-align: center;
  padding-left: 55px;
  padding-right: 55px;
}

.payment-container {
  max-width: 600px;
  box-shadow: 0px 12.1628px 24.3257px rgba(0, 0, 0, 0.08);
  padding-top: 20px;
  padding-bottom: 32px;
  justify-content: center;
}

h4 {
  margin-left: 25px;
  margin-right: 25px;

  /* Text default - 3C4257 */

  color: #3c4257;
}

.transactions-details {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 55px;
  padding-right: 55px;
  background: #f6f8fa;
  /* Border - EBEEF1 */

  border-width: 0.810857px 0px;
  border-style: solid;
  border-color: #ebeef1;

  margin-bottom: 26px;
}

.transaction-line {
  display: flex;
  justify-content: space-between;
}

button {
  background: #635bff;
  box-shadow: 0px -0.810857px 0.810857px rgba(0, 0, 0, 0.12),
    0px 1.62171px 4.05428px rgba(0, 0, 0, 0.12),
    0px 0.810857px 0.810857px rgba(0, 0, 0, 0.08);
  border-radius: 3.24343px;
  max-width: 340px;
  height: 38px;
  padding-right: 145px;
  padding-left: 145px;
  text-align: center;
  color: white;
  margin-top: 25px;
}

div.columns {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

div.header {
  display: flex;
  flex-direction: row;
  padding-left: 2rem;
  padding-right: 3rem;
  padding-top: 1.25rem;
  margin-bottom: 0.5rem;
  justify-content: space-between;
}

.label {
  color: #363636;
  display: block;
  font-size: 0.85rem;
  font-weight: 200;
}

.card {
  background-color: white;
  border-radius: 0.25rem;
  color: #4a4a4a;
  max-width: 100%;
  position: relative;
  border-style: solid;
  border-width: 1px;
  border-color: #ebeef1;
}

.card-header-title {
  align-items: center;
  color: #6a7383;
  display: flex;
  flex-grow: 1;
  font-weight: 600;
  padding: 0.75rem 0rem;
}

.pointer {
  cursor: pointer;
}
</style>
