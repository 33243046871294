import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
//import "buefy/dist/buefy.css";
import "./assets/style.css";
import VueI18n from "vue-i18n";
import { messages } from "@/i18n/index";
import "@mdi/font/css/materialdesignicons.css";
import VueMeta from "vue-meta";
//import CrispChat from "@dansmaculotte/vue-crisp-chat";
import { Crisp } from "crisp-sdk-web";
import { StripePlugin } from "@vue-stripe/vue-stripe";
import "vue-progress-path/dist/vue-progress-path.css";
import VueProgress from "vue-progress-path";
const options = {
  pk: "pk_live_51HrmdbE1srlewJHxdxuPjlaxc9HLrV5iw7wnVXcRlwT835RwAgU8BVjvXo1oxFbEWcsoRQFHMt2ffF91baPBZfqA00vi59LUbM",
  apiVersion: "3",
};

Vue.use(StripePlugin, options);
Crisp.configure("92734ee9-bcdb-42e2-a5d7-1d27f4df539c");

Vue.use(VueI18n);
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});
Vue.use(Buefy, {});

Vue.use(VueProgress, {
  defaultShape: "circle",
});
Vue.config.productionTip = false;
const i18n = new VueI18n({
  locale: navigator.language,
  messages,
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
