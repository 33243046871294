export const messages = {
  fr: {
    hello: {
      address: "Adresse",
      birthDate: "Date de naissance",
      startDate: "Date d'arrivée",
      endDate: "Date de départ",
      title: "Finalisez votre check-in avec {applicantName}",
      transactionDetails: "Détails de la transaction",
      bookingAmount: "Montant de la réservation",
      notDebitedDeposit: "Caution non débitée",
      debitedDeposit: "Dépôt de garantie",
      securityFees: "Frais de sécurisation",
      totalToPay: "Total à régler",
      firstName: "Prénom",
      lastName: "Nom",
      email: "Email",
      next: "Suivant",
      phoneNumber: "Téléphone",
      securityFeesTitle: "Frais de sécurisation",
      securityFeesTextWithoutFeesTitle: "Dépôt de garantie",
      securityFeesText:
        "Ces frais vous permettent de valider une empreinte bancaire (non débitée). Si vous préférez effectuer un dépôt de garantie, qui sera débité immédiatement puis remboursé intégralement 7 jours après votre départ, vous pouvez le faire en ",
      securityFeesTextWithoutFees:
        "Le dépôt de garantie est débité immédiatement, puis remboursé intégralement 7 jours après votre départ. Si vous préférez valider une caution non débitée, vous pouvez le faire en ",
      doSoHere: "cliquant ici.",
      commitmentTitle: "Engagement",
      commitmentText:
        "En acceptant cette transaction, j'autorise Yeswebook à débiter ma carte bancaire du montant indiqué, en cas de non-respect de mon engagement durant le séjour. Yeswebook vous protège et contrôle la légitimité des débits.",
      securityTitle: "Sécurité bancaire",
      securityText:
        "Les informations bancaires sont cryptées selon les derniers standards en vigueur et sont sécurisées chez notre partenaire bancaire. Nous ne sauvegardons, ni ne faisons transiter d'informations bancaires sur nos serveurs.",
      identityWillVerified:
        "À la prochaine étape, nous allons procéder à une vérification de votre pièce d'identité.",
    },
    success: {
      title: "Caution validée! 🎉",
      subTitle:
        "Envoyez immédiatement le message ci-dessous au propriétaire pour le prévenir, vous pouvez ajuster le message avant de l’envoyer si besoin.",
      subSubTitle:
        "Envoyez le message ci-dessous au propriétaire pour obtenir vos accès :",
      sendSMS: "Envoyer par SMS",
      copy: "Copier le message",
      copied: "Message copié",
      message: "Message",
    },
  },
  en: {
    hello: {
      address: "Address",
      birthDate: "Birth date",
      startDate: "Arrival date",
      endDate: "Departure date",
      title: "Complete your check-in with {applicantName}",
      transactionDetails: "Details of the transaction",
      bookingAmount: "Amount of the booking",
      notDebitedDeposit: "Deposit not debited",
      debitedDeposit: "Deposit debited",
      securityFees: "Security fees",
      totalToPay: "Total to pay",
      firstName: "First name",
      lastName: "Last name",
      email: "Email",
      next: "Next",
      phoneNumber: "Phone number",
      securityFeesTitle: "Security fees",
      securityFeesTextWithoutFeesTitle: "Security deposit",
      securityFeesText:
        "These fees allow you to validate a non-debited bank imprint. However, if you prefer to make a security deposit, debited immediately and fully refunded 7 days after your departure, you can do so by ",
      securityFeesTextWithoutFees:
        "The security deposit is charged immediately and fully refunded 7 days after your departure. If you prefer to provide an uncharged security deposit, you can do so by ",
      doSoHere: "clicking here.",
      commitmentTitle: "Commitment",
      commitmentText:
        "By accepting this transaction, I authorize Yeswebook to charge my credit card for the amount indicated in case of any breach of my commitment during the stay. Yeswebook ensures your protection and monitors the legitimacy of charges.",
      securityTitle: "Bank security",
      securityText:
        "Banking information is encrypted according to the latest standards in force and secured with our banking partner. We do not store or transit any banking information on our servers.",
      identityWillVerified: "In the next step, we will verify your ID.",
    },
    success: {
      title: "Congratulations, your transaction is complete! ",
      subTitle:
        "Send the message below to the owner immediately to notify them, you can adjust the message before sending it if needed.",
      subSubTitle: "Send the message below to the owner immediately.",
      sendSMS: "Send by SMS",
      copy: "Copy the message",
      copied: "Copied",
      message: "Message",
    },
  },
  es: {
    hello: {
      address: "Dirección",
      birthDate: "Fecha de nacimiento",
      startDate: "Fecha de llegada",
      endDate: "Fecha de salida",
      title: "Complete su registro con {applicantName}",
      transactionDetails: "Detalles de la transacción",
      bookingAmount: "Importe de la reserva",
      notDebitedDeposit: "Depósito no cargado",
      debitedDeposit: "Depósito cargado",
      securityFees: "Costos de aseguramiento",
      totalToPay: "Total a pagar",
      firstName: "Nombre",
      lastName: "Apellido",
      email: "Email",
      next: "Siguiente",
      phoneNumber: "Número de teléfono",
      securityFeesTitle: "Tarfias de seguridad",
      securityFeesTextWithoutFeesTitle: "Security deposit",
      securityFeesText:
        "Estas tarifas le permiten validar una huella bancaria (no debitada). Sin embargo, si prefiere hacer un depósito de seguridad, debitado de inmediato y reembolsado en su totalidad 7 días después de su partida, puede hacerlo haciendo ",
      securityFeesTextWithoutFees:
        "El depósito de garantía se carga inmediatamente y se reembolsa en su totalidad 7 días después de su salida. Si prefiere proporcionar un depósito de garantía no cargado, puede hacerlo haciendo ",
      doSoHere: "clic aquí.",
      commitmentTitle: "Compromiso",
      commitmentText:
        "Al aceptar esta transacción, autorizo a Yeswebook a cargar mi tarjeta de crédito por el monto indicado en caso de cualquier incumplimiento de mi compromiso durante la estancia. Yeswebook garantiza su protección y supervisa la legitimidad de los cargos.",
      securityTitle: "Seguridad bancaria",
      securityText:
        "La información bancaria está encriptada según los últimos estándares vigentes y se asegura con nuestro socio bancario. No almacenamos ni transmitimos ninguna información bancaria en nuestros servidores.",
      identityWillVerified: "En el siguiente paso, comprobaremos su identidad.",
    },
    success: {
      title: "¡Enhorabuena, tu transacción se ha completado!",
      subTitle:
        "Envía inmediatamente el siguiente mensaje al propietario para hacérselo saber, puedes ajustar el mensaje antes de enviarlo si es necesario.",
      subSubTitle: "Envíe el siguiente mensaje al propietario de inmediato.",
      sendSMS: "Enviar por SMS",
      copy: "Copiar el mensaje",
      copied: "Mensaje copiado",
      message: "Mensage",
    },
  },
};
