<template>
  <div class="home">
    <HelloWorld
      :payment-request-id="this.paymentRequestId"
      :without-id-verification="this.withoutIdVerification"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  data() {
    return {
      paymentRequestId: this.$route.params.paymentRequestId,
      withoutIdVerification: this.$route.query.withoutIdVerification == "true",
    };
  },
  components: {
    HelloWorld,
  },
};
</script>
<style scoped>
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
</style>
