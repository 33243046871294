<!-- eslint-disable vue/no-parsing-error -->
<!-- eslint-disable prettier/prettier -->
<template>
  <div class="payment-container">
    <div class="header">
      <h4 class="title is-5"> {{ $t('hello.title', { applicantName: applicantName }) }}</h4>
      <language-selector></language-selector>
    </div>
    <div class="transactions-details" v-if="pi && identiyVerified">
      <h6 class="title is-6">{{ $t('hello.transactionDetails') }}</h6>
      <div v-if="booking">
        <div class="transaction-line">
          <p>{{ $t('hello.bookingAmount') }}</p>
          <p> {{ new Intl.NumberFormat('fr-FR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(booking)
          }} EUR</p>
        </div>
        <hr class="rep" />
      </div>
      <div v-if="deposit">
        <div class="transaction-line">
          <p>{{ depositWithoutFees ? $t('hello.debitedDeposit') : $t('hello.notDebitedDeposit') }}</p>
          <p> {{ new Intl.NumberFormat('fr-FR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(deposit)
          }} EUR</p>
        </div>
        <hr class="rep" />
      </div>
      <div v-if="depositFees != null">
        <div class="transaction-line">
          <p>{{ $t('hello.securityFees') }}</p>
          <p> {{ new Intl.NumberFormat('fr-FR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(depositFees)
          }} EUR</p>
        </div>
      </div>
      <hr />
      <div class="transaction-line">
        <p><b>{{ $t('hello.totalToPay') }}</b></p>
        <p><b>{{ new Intl.NumberFormat('fr-FR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(totalAmount / 100)
        }} EUR</b></p>
      </div>
    </div>
    <div class="personal-data" v-if="!pi">
      <div class="columns is-mobile">
        <b-field :label="$t('hello.firstName')" class="column">
          <b-input v-model="firstName" type="text" :placeholder="$t('hello.firstName')"
            class="data-input-firstname"></b-input>
        </b-field>
        <b-field :label="$t('hello.lastName')" class="column">
          <b-input v-model="lastName" type="text" :placeholder="$t('hello.lastName')"></b-input>
        </b-field>
      </div>
      <b-field label="Email">
        <b-input v-model="email" type="email" placeholder="nom@example.com" class="data-input"></b-input>
      </b-field>
      <b-field :label="$t('hello.phoneNumber')">
        <b-input v-model="phoneNumber" type="tel" placeholder="+33 6 12 34 56 78" class="data-input"></b-input>
      </b-field>
      <div class="columns is-mobile" v-if="isPermalink">
        <b-field :label="$t('hello.startDate')" class="column">
          <b-datepicker position="is-top-right" :mobile-native="false"
            :min-date="new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1)"
            v-model="startDate" placeholder="Sélectionnez..." trap-focus>
          </b-datepicker>
        </b-field>
        <b-field :label="$t('hello.endDate')" class="column">
          <b-datepicker position="is-top-right" :mobile-native="false"
            :min-date="startDate ? startDate : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())"
            v-model="endDate" placeholder="Sélectionnez..." trap-focus>
          </b-datepicker>
        </b-field>
      </div>
      <div class="button-container">
        <b-button @click="requestPaymentIntent" :disabled="isButtonDisabled" type="is-primary">{{ $t('hello.next')
        }}</b-button>
      </div>
    </div>
    <div class="stripe-div" v-else-if="pi && identiyVerified">
      <stripe-element-payment v-on:error="onStripeError" v-on:loading="loading = !loading" v-on:element-change="onStripeElementChange" ref="paymentRef" :pk="pk"
        :elements-options="elementsOptions" :confirm-params="confirmParams" class="payment" />
      <b-button @click="pay" :disabled="loading || !stripeElementComplete" type="is-primary" :class="{'is-loading': loading}">
        {{ loading ? "Patientez ..." : "Valider (" + (new Intl.NumberFormat('fr-FR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(totalAmount / 100))
          + "€)" }}
      </b-button>
      <div class="collapses">
        <b-collapse class="card" animation="slide" v-for="(collapse, index) of collapses" :key="index"
          :open="isOpen == index" @open="isOpen = index" :aria-id="'contentIdForA11y5-' + index">
          <template #trigger="props">
            <div class="card-header" role="button" :aria-controls="'contentIdForA11y5-' + index"
              :aria-expanded="props.open">
              <a class="card-header-icon">
                <b-icon :icon="props.open ? 'chevron-down' : 'chevron-right'">
                </b-icon>
              </a>
              <p class="card-header-title">
                {{ collapse.securityFees == true ? (depositWithoutFees
                  ? $t("hello.securityFeesTextWithoutFeesTitle")
                  : $t("hello.securityFeesTitle")) : (collapse.commitment ? $t("hello.commitmentTitle") :
                    $t("hello.securityTitle")) }}
              </p>
            </div>
          </template>
          <div class="card-content">
            <div class="content">
              {{ collapse.securityFees == true ? (depositWithoutFees
                ? $t("hello.securityFeesTextWithoutFees")
                : $t("hello.securityFeesText")) : (collapse.commitment ? $t("hello.commitmentText") :
                  $t("hello.securityText")) }}<span class="pointer" style="text-decoration: underline"
                @click="activateWithoutFees" v-if="collapse.securityFees == true">{{ $t('hello.doSoHere') }}</span>
              <br />
              <b-image v-if="collapse.displayImage" :src="require(`../assets/security.png`)"></b-image>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
import PaymentRequestService from "@/services";
import LanguageSelector from "./atomic/LanguageSelector.vue";
import { Crisp } from "crisp-sdk-web";
export default {
  components: {
    StripeElementPayment,
    LanguageSelector,
  },
  props: {
    paymentRequestId: String,
    withoutIdVerification: Boolean,
  },
  data() {
    return {
      idVerificationSessionId: null,
      depositWithoutFees: false,
      identiyVerified: localStorage.identityVerified,
      verifyIdentityChecked: true,
      stripeCustomerId: null,
      flatId: null,
      isPermalink: null,
      startDate: null,
      endDate: null,
      pi: null,
      booking: null,
      deposit: null,
      depositFees: null,
      serviceFees: null,
      applicantName: null,
      acceptedTermsOfService: false,
      firstName: null,
      lastName: null,
      birthDate: null,
      address: null,
      phoneNumber: null,
      email: null,
      loading: false,
      stripeElementComplete: false,
      paymentRequest: null,
      pk: "pk_live_51HrmdbE1srlewJHxdxuPjlaxc9HLrV5iw7wnVXcRlwT835RwAgU8BVjvXo1oxFbEWcsoRQFHMt2ffF91baPBZfqA00vi59LUbM",
      elementsOptions: {
        appearance: {}, // appearance options
      },
      confirmParams: {
        return_url: null, // success url
      },
      isOpen: 0,
      collapses: [
        {
          securityFees: true,
          commitment: false,
          bankSecurity: false,
          title: this.$t("hello.securityFeesTitle"),
          text: this.depositWithoutFees
            ? this.$t("hello.securityFeesTextWithoutFees")
            : this.$t("hello.securityFeesText"),
          displayImage: false,
        },
        {
          securityFees: false,
          commitment: true,
          bankSecurity: false,
          title: this.$t("hello.commitmentTitle"),
          text: this.$t("hello.commitmentText"),
          displayImage: false,
        },
        {
          securityFees: false,
          commitment: false,
          bankSecurity: true,
          title: this.$t("hello.securityTitle"),
          text: this.$t("hello.securityText"),
          displayImage: true,
        },
      ],
    };
  },
  watch: {
    address(old, newV) {
      console.log(newV);
    },
  },
  computed: {
    isButtonDisabled() {
      return !this.firstName ||
        !this.lastName ||
        !this.email ||
        !this.phoneNumber ||
        !this.startDate ||
        !this.endDate
        ? true
        : false;
    },
    totalAmount() {
      return this.depositWithoutFees
        ? Math.round(
            ((this.deposit ? this.deposit : 0) +
              (this.booking ? this.booking : 0)) *
              100
          )
        : Math.round(
            ((this.booking ? this.booking : 0) +
              (this.depositFees ? this.depositFees : 0)) *
              100
          );
    },
  },
  created() {
    PaymentRequestService.getPaymentRequest(this.paymentRequestId)
      .then((response) => {
        this.paymentRequest = response.data;
        // If not permalink and already payed. Then directly to success page
        /*if (
          this.paymentRequest.payments.length != 0 &&
          !this.paymentRequest.isPermalink
        ) {
          this.$router.push({
            name: "success",
            query: {
              firstName: this.paymentRequest.payments[0].firstName,
              lastName: this.paymentRequest.payments[0].lastName,
              email: this.paymentRequest.payments[0].email,
              phoneNumber: this.paymentRequest.payments[0].phoneNumber,
              applicantName: this.paymentRequest.flatId,
              reservationId: this.paymentRequest.reservationId,
              startDate: this.paymentRequest.startDate,
              endDate: this.paymentRequest.endDate,
            },
          });
        }*/
        this.isPermalink = this.paymentRequest.isPermalink;
        this.flatId = this.paymentRequest.flatId;
        this.applicantName = this.paymentRequest["owner"]["commercialName"];
        this.booking = this.paymentRequest.booking;
        this.deposit = this.paymentRequest.deposit;
        this.depositFees = this.paymentRequest.depositFees;
        this.serviceFees = this.paymentRequest.serviceFees;
        if (this.paymentRequest.type == "BOOKING_ONLY") {
          this.collapses.shift();
        }
        if (!this.paymentRequest.isPermalink) {
          this.startDate = this.paymentRequest.startDate;
          this.endDate = this.paymentRequest.endDate;
        }
        Crisp.session.setData({
          flat: this.flatId,
          applicantName: this.applicantName,
        });
      })
      .catch((error) => {
        window.location.href = "/error";
        console.error(error);
      }).pi;
  },
  mounted() {
    // eslint-disable-next-line no-undef
    const autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autoc")
    );
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      this.address = place["formatted_address"];
    });
  },
  methods: {
    activateWithoutFees() {
      this.depositWithoutFees = !this.depositWithoutFees;

      this.depositFees = this.depositWithoutFees
        ? 0.0
        : this.paymentRequest.depositFees;
      this.requestPaymentIntent();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      console.log("without fees changed");
    },
    getGoogleMapsPredictions() {
      PaymentRequestService.getGoogleMapsPredictions(this.addressInput)
        .then((response) => (this.addressPredictions = response))
        .catch((error) => error);
    },
    onStripeError(error) {
      console.log(error.message);
      this.$buefy.notification.open({
        message: error.message,
        position: "is-bottom-right",
        type: "is-danger",
        hasIcon: true,
      });
    },
    onStripeElementChange(change) {
      this.stripeElementComplete = change.complete;
    },
    reset() {
      this.$router.push({
        name: "home",
        params: { paymentRequestId: this.paymentRequest.shortId },
      });
    },
    pay() {
      this.$refs.paymentRef.submit();
    },
    verifyIdentity() {
      PaymentRequestService.createIdVerificationSession(
        this.paymentRequest.id,
        this.firstName,
        this.lastName,
        this.phoneNumber,
        this.email
      )
        .then((response) => {
          console.log(response);
          this.idVerificationSessionId = response.data.id;
          return this.$stripe.verifyIdentity(response.data.clientSecret);
        })
        .then((result) => {
          // If `verifyIdentity` fails, display the localized
          // error message to your user using `error.message`.
          if (result.error) {
            alert(
              "Votre identité n'a pas été validée. Veuillez valider votre identité avant de continuer"
            );
            this.pi = null;
          } else {
            this.identiyVerified = true;
            localStorage.setItem("identityVerified", true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    requestPaymentIntent() {
      Crisp.session.setData({
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: this.phoneNumber,
        startDate: this.startDate
          ? new Date(this.startDate).toLocaleDateString()
          : "",
        endDate: this.endDate
          ? new Date(this.endDate).toLocaleDateString()
          : "",
        ...(this.booking ? { booking: this.booking } : {}),
        ...(this.deposit ? { deposit: this.deposit } : {}),
        ...(this.depositFees ? { depositFees: this.depositFees } : {}),
      });
      Crisp.user.setNickname(this.firstName + " " + this.lastName);
      if (!this.identiyVerified && this.paymentRequest.verifyIdentity) {
        this.verifyIdentity();
      } else {
        this.identiyVerified = true;
      }
      PaymentRequestService.createPaymentIntent(
        this.firstName,
        this.lastName,
        this.email,
        this.phoneNumber,
        this.paymentRequest.id,
        this.depositWithoutFees
      )
        .then(async (response) => {
          this.pi = undefined;
          await this.$nextTick();
          this.elementsOptions.clientSecret = response.data.pi;
          this.stripeCustomerId = response.data.stripeCustomerId;
          console.log(
            "Payment Intent Asked",
            this.elementsOptions.clientSecret
          );
          this.pi = response.data.pi;
          this.confirmParams.return_url = encodeURI(
            "https://yeswebook.co/success/" +
              this.paymentRequest.id +
              `?firstName=${this.firstName}&lastName=${this.lastName}&email=${
                this.email
              }&phoneNumber=${this.phoneNumber}&totalAmount=${
                this.totalAmount
              }&applicantName=${this.paymentRequest.flatId}&reservationId=${
                this.paymentRequest.reservationId
              }&startDate=${new Date(
                this.startDate
              ).toISOString()}&endDate=${new Date(
                this.endDate
              ).toISOString()}&isPermalink=${
                this.paymentRequest.isPermalink
              }&stripeCustomerId=${
                this.stripeCustomerId
              }&idVerificationSessionId=${this.idVerificationSessionId}`
          );
          console.log(this.confirmParams);
          this.$el.getElementsByClassName("stripe-div").value = false;
        })
        .catch((error) => console.error(error));
    },
  },
};
</script>
<style scoped>
.collapses {
  margin-top: 55px;
}

.data-input {
  margin-bottom: 1.5rem;
}

.data-input-firstname {
  margin: 0px 0px 0px 0px;
}

.column {
  margin: 0px 0px 0.65rem 0px;
}

hr {
  border: 0;
  border-top: 1px solid;
  margin-top: 7px;
  margin-bottom: 7px;
}

hr.rep {
  border: 0;
  border-top: 0.3px solid #dedfe0;
  margin-top: 7px;
  margin-bottom: 7px;
}

.button-container {
  text-align: center;
}

.personal-data {
  padding-left: 55px;
  padding-right: 55px;
}

.stripe-div {
  text-align: center;
  padding-left: 55px;
  padding-right: 55px;
}

.payment-container {
  max-width: 600px;
  box-shadow: 0px 12.1628px 24.3257px rgba(0, 0, 0, 0.08);
  padding-top: 20px;
  padding-bottom: 32px;
  justify-content: center;
}

h4 {
  margin-left: 25px;
  margin-right: 25px;

  /* Text default - 3C4257 */

  color: #3c4257;
}

.transactions-details {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 55px;
  padding-right: 55px;
  background: #f6f8fa;
  /* Border - EBEEF1 */

  border-width: 0.810857px 0px;
  border-style: solid;
  border-color: #ebeef1;

  margin-bottom: 26px;
}

.transaction-line {
  display: flex;
  justify-content: space-between;
}

button {
  background: #635bff;
  box-shadow: 0px -0.810857px 0.810857px rgba(0, 0, 0, 0.12),
    0px 1.62171px 4.05428px rgba(0, 0, 0, 0.12),
    0px 0.810857px 0.810857px rgba(0, 0, 0, 0.08);
  border-radius: 3.24343px;
  max-width: 340px;
  height: 38px;
  padding-right: 145px;
  padding-left: 145px;
  text-align: center;
  color: white;
  margin-top: 25px;
}

div.columns {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

div.header {
  display: flex;
  flex-direction: row;
  padding-left: 2rem;
  padding-right: 3rem;
  padding-top: 1.25rem;
  margin-bottom: 0.5rem;
  justify-content: space-between;
}

.label {
  color: #363636;
  display: block;
  font-size: 0.85rem;
  font-weight: 200;
}

.card {
  background-color: white;
  border-radius: 0.25rem;
  color: #4a4a4a;
  max-width: 100%;
  position: relative;
  border-style: solid;
  border-width: 1px;
  border-color: #ebeef1;
}

.card-header-title {
  align-items: center;
  color: #6a7383;
  display: flex;
  flex-grow: 1;
  font-weight: 600;
  padding: 0.75rem 0rem;
}

.pointer {
  cursor: pointer;
}
</style>
