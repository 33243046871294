import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import AdditionalSales from "../views/AdditionalSalesView.vue";
import Error from "../views/Error.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/pr/:paymentRequestId",
    name: "home",
    component: HomeView,
  },
  {
    path: "/success/:paymentRequestId",
    name: "success",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SuccessView.vue"),
  },
  {
    path: "/additional-sales-success/:paymentId",
    name: "additional-sales-success",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AdditionalSalesSuccess.vue"
      ),
  },
  {
    path: "/additional-sales/:paymentId",
    name: "additionalSales",
    component: AdditionalSales,
  },
  {
    path: "/error",
    name: "error",
    component: Error,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
