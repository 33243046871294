<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

* {
  margin: 0;
  padding: 0;
}
</style>
<script>
export default {
  name: "App",
  metaInfo: {
    title: "Secure your deposits & payments.",
    meta: [
      {
        property: "og:title",
        content: "Secure your deposits & payments.",
        vmid: "og:title",
      },
      {
        property: "og:image",
        vmid: "og:image",
        content:
          "https://publicdataywb.s3.eu-west-3.amazonaws.com/metaimageywb.png",
      },
    ],
  },
};
</script>
